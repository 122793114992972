import api from "@/api"
import BreadCrumbs from "@/components/BreadCrumbs"
import { MonthData } from "@/api/modules/select";
import { DefaultSetting, MetaRow, ListOpions } from "@/models/type"
import { parseTime, sharer } from "@/utils"
import { setup, Vue } from "vue-class-component"
import { useMeta } from "vue-meta"
import { RouteLocationMatched } from "vue-router"
import { SmsActivity } from ".."
import './index.scss'

export default class ActivityActivityDetail extends Vue {
  public detailId = 0
  public activeIndex = 0
  public monthId = 0
  public monthList: Array<ListOpions> = []

  public activityObj: SmsActivity = {}
  public articleObj: SmsActivityArticle = {}
  public activityList: Array<any> = []
  public imgUrl = ''
  public monthActivityList = [
    {
      id: 15,
      title: '中華文化課程',
      position: '香港九龍尖沙咀新東海商業中心一樓106-107室',
      introduce:  '空間舉辦了多個與中華文化有關的班組，包括中樂類、棋藝類、書畫類等等課程，一直以來廣受青少年的歡迎。以師傅帶徒弟的方式邀請駐場藝術家、訪問匠人、學者入駐空間，希望透過知識的傳播和教育讓年輕人有平台和機遇更加關注文化傳承。',
      coverImg: '/img/active/6.jpg'
    },
     {
      id: 16,
      title: '中華文化工作坊',
      position: '香港九龍尖沙咀新東海商業中心一樓106-107室',
      introduce:  '空間會定期舉辦不同的中華文化工作坊、新方式新類型活動，包括文化創意類、傳統手藝類、職業導向類，傳播中華文化正能量，吸引和鼓勵更多青年人創業，走進大灣區，走進大中華，展開社會服務工作，促進青少年的發展同增進社會共融。',
      coverImg: '/img/active/7.jpg'
    },
    {
      id: 17,
      title: '其他（展覽、比賽、合作活動等）',
      position: '香港九龍尖沙咀新東海商業中心一樓106-107室',
      introduce:  '空間會定期舉辦不同的畫展、影展、合辦活動、比賽等，透過各類活動建立青少年對中華文化自信，以文創、藝創、科創加上創新、創意、創業傳播中華優秀文化藝術的正能量，共同參與到弘揚中華優秀文化的事業，加快中華優秀文化藝術「走出去」的步伐，共建和諧美好社會環境。',
      coverImg: '/img/active/5.jpg'
    }
  ]


  metaList: Array<MetaRow> = []

  meta = setup(() => {
    const { meta } = useMeta({
      meta: this.metaList
    })
    return meta
  })

  getDetail(): void {
    const id = this.$route.params.id as never
    this.detailId = id
    api.getActivityById(id).then(res => {
      this.articleObj = res.data.article
      this.activityObj = res.data.activity
      this.imgUrl =  res.data.activity.mainImg?.split(',')[0]
      this.getMeta()
    })
  }

  getMeta(): void {
    this.metaList.splice(0, this.metaList.length)
    this.metaList.push(
      {property: 'og:title', content: this.activityObj.title as string},
      {property: 'og:description', content: this.activityObj.introduce as string},
      {property: 'og:image', content: this.activityObj.coverImg as string}
    )
  }

  signup(): void {
    const arr = this.$route.matched
    const obj = arr[arr.length - 1] as RouteLocationMatched
    obj.path = obj.path.replace(':id', this.$route.params.id as string)
    this.$router.push({
      name: 'ActivityActivitySignup',
      params: obj as any,
      query: {
        id: this.$route.params.id
      }
    })
  }

  render(): JSX.Element {
    return <div class="activity_activity_detail">
      <BreadCrumbs style="padding: 20px 0;"/>
      <div style={{backgroundImage: 'url(/img/active/bg.jpg)',backgroundSize: '100% 100%',backgroundRepeat: 'no-repeat'}}>
        <el-row>
          <el-col xs={24} sm={24} md={24} lg={12} xl={12}>
            <div class="d-left">
              <div class='direction direction-left'
                onClick={() => this.activeChange(this.activeIndex - 1)}
              >
                <i class='el-icon-arrow-left'></i>
              </div>
              <img class="d-left-img" src={this.monthActivityList[this.activeIndex]?.coverImg}  />
              <div class='direction direction-right'
                onClick={() => this.activeChange(this.activeIndex + 1)}
              >
                <i class='el-icon-arrow-right'></i>
              </div>
            </div>
          </el-col>
          <el-col xs={24} sm={24} md={24} lg={12} xl={12}>
            <div class="d-right">
              <div class="d-right-header">
                <div class="d-right-header-title">{this.monthActivityList[this.activeIndex]?.title}</div>
                {
                  this.activityObj.title
                    && <div class="d-right-header-fun">
                      <share-network
                        network="facebook"
                        url={window.location.href}
                        title={this.monthActivityList[this.activeIndex]?.title}
                        description={this.monthActivityList[this.activeIndex]?.introduce}
                      >
                        <div class="facebook"/>
                      </share-network>
                      <div class="wechat" onClick={() => sharer(2)} />
                      <share-network
                          network="whatsapp"
                          url={window.location.href}
                          title={this.monthActivityList[this.activeIndex]?.title}
                          description={this.monthActivityList[this.activeIndex]?.introduce}
                        >
                        <div class="phone"/>
                      </share-network>
                  </div>
                }
              </div>
              <div class="d-right-info">
                <div class="d-right-info-c">
                  <div class="d-right-cell">
                    <div>
                      <i class="el-icon-location-outline"/>
                      <span>地點</span>
                    </div>
                    <div>{this.monthActivityList[this.activeIndex]?.position}</div>
                  </div>
                  {/* <div class="d-right-cell">
                    <div>
                      <i class="el-icon-date"/>
                      <span>时间</span>
                    </div>
                    <div>{parseTime(new Date(this.activityObj.activityTime as never).getTime(), '{y}.{m}.{d}')}</div>
                  </div> */}
                  {/* <div class="d-right-btn" onClick={this.signup}>立即参加</div> */}
                </div>
              </div>
              <div class="d-right-content">
                <div>
                  <span>活動內容</span>
                </div>
                <p>{this.monthActivityList[this.activeIndex]?.introduce}</p>
              </div>
              {/* <div class="d-right-article">
                <div style={{
                  width: '70%'
                }}>
                  <div>{this.articleObj.title}</div>
                  <p>{this.articleObj.introduce}</p>
                </div>
                <img
                  src={this.articleObj.imgUrl}
                  alt=""
                />
              </div> */}
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  }
  activeChange(index: number): void {
    if (index === -1) {
      this.activeIndex = 2
    } else if (index === 3) {
      this.activeIndex = 0
    } else {
      this.activeIndex = index
    }
    console.log(this.activeIndex, 'this.activeIndex');
    
    // this.getMeta()
  }
 // 获取当月的文化内容
  getMounth(): void {
    const year = new Date().getFullYear() // 获取当年
    const month = new Date().getMonth() + 1 // 获取当月
    api.getMonth({ year }).then(res => {
      this.monthList = res.data.map((item: MonthData) => ({ name: item.fullName, value: item.id }))
      this.monthId = res.data.find((item: MonthData) => item.endMonth === month || item.startMonth === month)?.id
      this.getMonthActivity(this.monthId)
    })
  }

  
  getMonthActivity(monthId: number): void {
    api.subject_activity({ monthId }).then(res => {
      const articleAndActivityResultList = res.data.articleAndActivityResultList as Array<any>
      const arr: Array<SmsActivity> = []
      articleAndActivityResultList.forEach(item => {
        arr.push(...item.smsActivityList as Array<SmsActivity>)
      })
      // this.monthActivityList = arr

      this.getDetail()
    })
  }
  mounted(): void {
    // this.getMounth()
    this.getDetail()
    const id = this.$route.params.id as never
    const index = this.monthActivityList.findIndex(item => item.id == id)
    index != -1 ? this. activeIndex = index : this. activeIndex = 0
  }
}


/**
 * @description 艺术家
 * @author Jinx
 * @date 2021-08-26 15:33:43
 * @export
 * @interface SmsActivityArticle
 * @extends {DefaultSetting}
 */
 export interface SmsActivityArticle extends DefaultSetting {
  imgUrl?: string // 照片
  introduce?: string // 介绍
  title?: string // 名称
  phone?: string // 手机号
  email?: string // 邮箱
  position?: string
}
